<template class="fast-body-modo-aluno">
  <div>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container fast-plataforma-iuea-hide">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide mb-0">
            <li class="fd-app-breadcrumb-item">
              <a href="/minhas-plataformas" class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')">Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a :href="'/plataforma/' + $route.params.id_plataforma" class="aluno_font_color" @click.prevent="
                $router.push('/plataforma/' + $route.params.id_plataforma)
                ">Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'" @click.prevent="
                $router.push(
                  '/plataforma/' + $route.params.id_plataforma + '/aluno'
                )
                ">Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Tutoriais</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content mt-0">
        <div class="container">
          
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <!-- fd-app-welcome -->
              <div class="mt-3 mb-4">
                <a class="btn-novo btn-red-hollow-novo"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')">
                  <b-icon-arrow-return-left /> Voltar
                </a>
              </div>

              <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                <h2 class="aluno_font_color mb-0">
                  Tutoriais
                </h2>
                <p class="aluno_font_color my-0">Aprenda a utilizar a plataforma</p>
                <div>
                  <img :src="require('@/assets/images/separador.png')
                    " />
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <!-- Conteúdo principal -->
              <div class="fd-app-table-novo col-12 p-0 mt-4">
                <div class="col-12">
                  <h5 class="mb-0 pl-3 py-1">Lista de Tutoriais</h5>
                </div>
                <div class="col-12 table-responsive m-0 p-0">
                  <table class="table table-sm mb-2">
                    <thead>
                      <tr class="text-center">
                        <th>
                          <small class="font-weight-bold">Descrição</small>
                        </th>
                        <th>
                          <small class="font-weight-bold">Conteúdo</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastTutorialTotal.length">
                      <tr v-for="Tutorial in fastTutorialTotal" :key="Tutorial.id_tutorial">
                        <td class="align-middle text-center">
                          {{ Tutorial.descricao }}
                        </td>

                        <td class="text-center">
                          <a :href="ajustaLinkApiAntiga(Tutorial.url)"
                            v-if="Tutorial.tipo == 'A'"  
                            class="btn-novo btn-primary btn-sm mt-2 ml-2 text-nowrap"
                            target="_blank" download
                          >
                            <small class="text-light">
                              <b-icon-eye font-scale="1.2"/>
                              &nbsp;Visualizar
                            </small>
                          </a>
                          <a href="#" v-else-if="Tutorial.tipo == 'V'" 
                            class="btn-novo btn-primary btn-sm my-2 ml-2 text-nowrap" 
                            @click="visualizarAtividade( Tutorial)"
                          >
                            <small class="text-light">
                              <b-icon-eye font-scale="1.2"/>
                              &nbsp;Visualizar
                            </small>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="9" class="text-center">
                          Nenhum Tutorial encontrado
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--termina grid -->
                </div>
              </div>
              <!-- /Conteúdo principal -->
            </div>
          </div>

        </div>
        <!-- /fd-wrap -->
        <!-- modal -->
        <modal name="modalVisualizarAtividade" :scrollable="true" height="auto" :shift-y="0.1" :focus-trap="true"
          :adaptive="true">
          <div class="row p-4">
            <div class="col-6 pl-4 pr-4">
              <h4>Tutorial - {{ fastTutorialVisualiza.descricao }}</h4>
            </div>
            <div class="col-6 pl-4 pr-4 text-right">
              <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalVisualizarAtividade')">
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div v-if="fastTutorialVisualiza.tipo == 'V'" class="col-12">
                  <iframe :src="ajustaLinkApiAntiga(fastTutorialVisualiza.url)" width="100%" height="315"
                    frameborder="0" />
                </div>
                <div v-else class="col-12">
                  <audio controls class="d-block m-auto">
                    <source :src="ajustaLinkApiAntiga(fastTutorialVisualiza.url)" type="audio/mpeg" />
                    Seu navegador não suporte o player de audio.
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
// Descomentar componente
import muralAvisos from "../../components/MuralAvisos";
import Pagination from "../../components/Pagination";

export default {
  // Nome do componente
  name: "HomeInternoAlunoTutoriais",
  // Componentes
  components: {
    muralAvisos,
    Pagination,
  },
  // Carrega métodos globais
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTemplate: settings.fastTemplate,
      fastTutorialTotal: [],
      fastTutorialVisualiza: {},
    };
  },
  // Carrega componente de forma assíncrona
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  // Após carregar componente
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
      this.getFastDependecias(this.$route.params.id_plataforma, "aluno")
        .then(() => {
          // Iniciar aqui
          this.getTutorial(this.$route.params.id_plataforma);
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
    async getTutorial(id_plataforma) {
      this.promiseGetFastApi(
        "api/fast_plataforma_tutorial/lista",
        "id_plataforma=" + id_plataforma
      )
        .then((obj) => {
          if (obj.length) {
            this.fastTutorialTotal = obj;
          } else {
            this.fastTutorialTotal = [];
          }
          this.$store.state.fastCarregando = false;
          this.fastTutorialLoading = false;
        })
        .catch((e) => {
          this.exibeToasty("Erro ao buscar Tutorial", "error");
          this.$store.state.fastCarregando = false;
          this.fastTutorialficaLoading = false;
        });
    },

    visualizarAtividade(Tutorial) {

      this.fastTutorialVisualiza = Tutorial;
      if (Tutorial.tipo == "V") {
        if (Tutorial.url.indexOf("https://vimeo.com/") != "-1") {
          Tutorial.url =
            "https://player.vimeo.com/video/" + Tutorial.url.split("/").pop();
        }
      }
      this.showModal("modalVisualizarAtividade");
    },
  },
};
</script>

<style scope></style>
